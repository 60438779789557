import React, { lazy, Suspense } from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
const Home = lazy(() => import('./components/home/Home'));
const OnlineClassHelp = lazy(() => import('./components/services/online-class-help/OnlineClassHelp'));
const OnlineCourseHelp = lazy(() => import('./components/services/online-course-help/OnlineCourseHelp'));
const OnlineExamHelp = lazy(() => import('./components/services/online-exam-help/OnlineExamHelp'));
const ContactUs = lazy(() => import('./components/contact-us/ContactUs'));
const ForStudents = lazy(() => import('./components/for-students/Index'));
const HowItWorks = lazy(() => import('./components/how-it-works/HowItWorks'));
const Thanks = lazy(() => import('./components/thank-you/Thanks'));
const NotFound = lazy(() => import('./components/not-found/NotFound'));
const Navbar = lazy(() => import('./components/navbar/Navbar'));
const Footer = lazy(() => import('./components/footer/Footer'));

const App = () => {
  return (
    <Router>
      <Suspense
        fallback="Loading...."
      >
        <Navbar />
        <Switch>
          <Route path={'/'} exact component={() => <Home />} />
          <Route path={'/for-students'} exact component={() => <ForStudents />} />
          <Route path={'/how-it-works'} exact component={() => <HowItWorks />} />
          <Route path={'/online-class-help'} exact component={() => <OnlineClassHelp />} />
          <Route path={'/online-course-help'} exact component={() => <OnlineCourseHelp />} />
          <Route path={'/online-exam-help'} exact component={() => <OnlineExamHelp />} />
          <Route path={'/contact-us'} exact component={() => <ContactUs />} />
          <Route path={'/thank-you'} exact component={() => <Thanks />} />
          <Route path={'*'} exact component={() => <NotFound />} />
        </Switch>
        <Footer />
      </Suspense>
    </Router>
  )
}
export default App